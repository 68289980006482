import React, { useState } from "react";
import Modal from "./modal";
import KeepMePostedForm from "./keep-me-posted-form";

function KeepMePosted({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      {children && <div onClick={openModal}>{children}</div>}
      <Modal closeModal={closeModal} modalOpen={isOpen}>
        <KeepMePostedForm />
      </Modal>
    </>
  );
}

export default KeepMePosted;
