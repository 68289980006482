import React, { useState } from "react";
import { GoogleSpreadsheet } from "google-spreadsheet";
import ReCAPTCHA from "react-google-recaptcha";
import sheetsConfig from "../sheets-automation.json";
import Input from "./input";
import Button from "./button";
import Select from "./select";
import axios from "axios";
import KeepMePostedFormSuccess from "./form-success-screen";

const descriptionList = [
  "Food Maker",
  "Commercial Kitchen",
  "Restaurant",
  "Investor",
  "Food Lover",
];

function KeepMePostedForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    description: "",
    town: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [verify, setVerify] = useState(false);

  const SPREADSHEET_ID = "159be6bzLDSuaj3xObEvxGKCWuvjxNDbDJTYJpB_kX50";
  const SHEET_ID = "0";
  const CLIENT_EMAIL = sheetsConfig.client_email;
  const PRIVATE_KEY = sheetsConfig.private_key;

  const doc = new GoogleSpreadsheet(SPREADSHEET_ID);

  const { firstName, lastName, email, description, town } = formData;

  const fetchPlaces = async (query) => {
    const key = "AIzaSyDcmoMvbe9UU6ADUcBQfT3krCU0Ft3-Krs";
    if (query.length < 3) return [];

    const res = await axios.get(
      `https://api.allorigins.win/get?url=${encodeURIComponent(
        `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${query}&types=(cities)&key=${key}`
      )}`
    );

    const contents = JSON.parse(res.data.contents) || {};
    return contents.predictions?.map((prediction) => ({
      value: prediction.description,
      label: prediction.description,
    }));
  };

  const handleInputChange = (evt) =>
    setFormData({ ...formData, [evt.target.name]: evt.target.value });

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: PRIVATE_KEY,
      });
      // loads document properties and worksheets
      await doc.loadInfo();

      const sheet = doc.sheetsById[SHEET_ID];
      sheet.addRow(row);
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      firstName,
      lastName,
      email,
      description: { value: description },
      town: { value: town },
    } = formData;

    const newRow = {
      "First Name": firstName,
      "Last Name": lastName,
      "Email Address": email,
      Description: description,
      State: town,
      Date: new Date().toISOString(),
    };
    await appendSpreadsheet(newRow);

    setFormSubmitted(true);
  };

  return !formSubmitted ? (
    <div className={"-mt-10"}>
      <h3
        className="capitalize text-dark"
        style={{ fontWeight: 800, fontSize: "22px" }}
      >
        Stay Updated
      </h3>
      <form onSubmit={handleSubmit} className={"space-y-4 md:space-y-6 mt-4"}>
        <div className={"grid grid-cols-2 gap-3 md:gap-6"}>
          <Input
            title={"First Name"}
            name={"firstName"}
            value={firstName}
            onChange={handleInputChange}
            placeholder={"First Name"}
            required
          />
          <Input
            title={"Last Name"}
            name={"lastName"}
            value={lastName}
            onChange={handleInputChange}
            placeholder={"Last Name"}
          />
        </div>
        <Input
          title={"Email Address"}
          name={"email"}
          type={"email"}
          value={email}
          onChange={handleInputChange}
          placeholder={"name@example.com"}
          required
        />
        <Select
          title={"I am a:"}
          name={"description"}
          value={description}
          options={descriptionList}
          onChange={handleInputChange}
          placeholder={"Select what describes you best"}
          required
        />
        <Select
          async
          fetchOptions={fetchPlaces}
          title={"Town or State"}
          name={"town"}
          value={town}
          onChange={handleInputChange}
          placeholder={"Select your town/state"}
          required
        />
        <ReCAPTCHA
          sitekey="6LebAx4cAAAAAOMnf7ggOZOUMCqc79ZvMD2-OKVk"
          onChange={() => setVerify(!verify)}
        />
        <Button
          disabled={
            !verify || !Object.values(formData).every((value) => !!value)
          }
          className={"w-full"}
          type={"submit"}
        >
          Keep Me Posted!
        </Button>
      </form>
    </div>
  ) : (
    <KeepMePostedFormSuccess />
  );
}

export default KeepMePostedForm;
