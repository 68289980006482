import PropTypes from "prop-types";
import React from "react";
import Header from "./header";

function Layout({ children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-dark">
      <Header />

      <main className="flex-1 w-full">{children}</main>

      <footer className="p-6 text-center shadow shadow-sm">
        <div className={"grid md:grid-cols-2 md:divide-x max-w-lg mx-auto"}>
          <p className={"font-bold text-sm md:text-base"}>
            Copyright © NearPlate {new Date().getFullYear()}
          </p>
          <a
            className={"hover:underline cursor-pointer"}
            href={"mailto:support@nearplate.com"}
          >
            ✉️ support@nearplate.com
          </a>
        </div>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
