import React from "react";
import ReactModal from "react-modal";

ReactModal.setAppElement(`#___gatsby`);

const modalStyles = {
  overlay: {
    position: "fixed",
    backgroundColor: "rgba(33, 43, 54, 0.58)",
    zIndex: 100,
    backdropFilter: "blur(3px)",
    WebkitBackdropFilter: "blur(3px)",
    overflowY: "scroll",
  },
  content: {
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    maxWidth: "560px",
    margin: "32px auto",
    padding: 0,
    border: 0,
  },
};

function Modal({
  customModalStyles = {},
  modalOpen,
  closeModal,
  children,
  title,
}) {
  return (
    <ReactModal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      style={{ ...modalStyles, ...customModalStyles }}
      contentLabel="Modal"
    >
      <div className={`p-8 md:p-10 w-full`}>
        <div className="flex justify-between mb-4">
          <div className="flex flex-col">
            {title && (
              <h3
                className="capitalize text-dark"
                style={{ fontWeight: 800, fontSize: "22px" }}
              >
                {title}
              </h3>
            )}
          </div>
          {closeModal && (
            <span className="z-20 cursor-pointer" onClick={closeModal}>
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.0001 13.5605L6.53039 19.0301L5.46973 17.9695L10.9394 12.4998L5.46973 7.03015L6.53039 5.96949L12.0001 11.4392L17.4697 5.96948L18.5304 7.03014L13.0607 12.4998L18.5304 17.9695L17.4697 19.0301L12.0001 13.5605Z"
                  fill="#83888E"
                />
              </svg>
            </span>
          )}
        </div>
        {children}
      </div>
    </ReactModal>
  );
}

export default Modal;
