import { Link } from "gatsby";
import React from "react";
import Button from "./button";
import Logo from "../images/logo.svg";
import KeepMePosted from "./keep-me-posted";

function Header() {
  return (
    <header className="sticky top-0 bg-white px-4 md:px-8 w-full z-10">
      <div
        className="max-w-screen-2xl mx-auto flex flex-wrap items-center justify-between py-4 md:py-4 border-b"
        style={{ borderColor: "#F1F3F3" }}
      >
        <Link to="/">
          <img alt="Cat and human sitting on a couch" className="" src={Logo} />
        </Link>

        <nav className={`hidden sm:block md:items-center w-full md:w-auto`}>
          {[
            {
              route: `#stories`,
              title: `Cook Stories`,
            },
            {
              route: `#partners`,
              title: `Partners`,
            },
            {
              route: `#`,
              title: (
                <KeepMePosted>
                  <Button small>Keep Me Posted!</Button>
                </KeepMePosted>
              ),
            },
          ].map((link) => (
            <Link
              className="block mt-4 no-underline md:inline-block md:mt-0 md:ml-6 font-bold"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
