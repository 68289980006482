import React from "react";

export default function Input({ title, name, value, onChange, ...props }) {
  return (
    <div className={"flex flex-col space-y-2"}>
      <label
        className={"inline-block text-dark-100 font-medium space-y-2"}
        htmlFor={name}
      >
        {title}
      </label>
      <input
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        className={
          "appearance-none border border-dark-300 rounded rounded-xl p-4 text-dark outline-none focus:border-dashed focus:border-dark-100"
        }
        {...props}
      />
    </div>
  );
}
