import React from "react";
import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const colourStyles = {
  input: (styles) => ({
    ...styles,
  }),
  control: (styles) => ({
    ...styles,
    borderRadius: ".75rem",
    fontSize: "16px",
    outline: "none",
    padding: "0.7rem",
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: ".75rem",
  }),
  placeholder: (styles) => ({ ...styles, color: "#596068" }),
};

const ComponentSelector = (props) => {
  const { async, fetchOptions } = props;

  const promiseOptions = async (inputValue, callback) => {
    const options = await fetchOptions(inputValue);
    callback(options);
  };

  return async ? (
    <AsyncSelect
      cacheOptions
      // defaultOptions
      loadOptions={promiseOptions}
      {...props}
    />
  ) : (
    <ReactSelect {...props} />
  );
};

export default function Select({
  title,
  name,
  value,
  onChange,
  options,
  required,
  disabled,
  ...otherProps
}) {
  const modifiedOptions = options?.map((option) =>
    typeof option === "object" ? option : { value: option, label: option }
  );

  const handleChange = (value) => onChange({ target: { name, value } });

  return (
    <div className={"relative flex flex-col space-y-2"}>
      <label
        className={"inline-block text-dark-100 font-medium space-y-2"}
        htmlFor={name}
      >
        {title}
      </label>
      <ComponentSelector
        className="react-select-container"
        styles={colourStyles}
        components={animatedComponents}
        options={modifiedOptions}
        value={value}
        name={name}
        onChange={handleChange}
        disabled={disabled}
        required={required}
        {...otherProps}
      />
      {!disabled && (
        <input
          className={"absolute top-0"}
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: "1px", top: "3.5rem", left: "1rem" }}
          value={value}
          onChange={() => {}}
          required={required}
        />
      )}
    </div>
  );
}
