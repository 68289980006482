import React from "react";

function Button({ small, children, className, disabled, ...otherProps }) {
  return (
    <button
      className={`${
        small ? "p-2 px-4" : "py-5 p-8"
      } rounded-full text-white bg-primary font-bold transform  ease-linear duration-200 ${
        disabled ? "text-dark" : "text-white hover:scale-105"
      } ${className}`}
      style={disabled ? { backgroundColor: "#F1F3F3" } : {}}
      {...otherProps}
    >
      {children}
    </button>
  );
}

export default Button;
